import React from 'react'

// CSS
import 'styles/Contact.scss'

// Components
import Layout from 'components/Layout'

function BedanktContact() {
  return (
    <Layout>
      <div className="contact">
        <div className="container py-5">
          <div className="row pb-5 d-flex justify-content-center">
            <p className="font-size-xl font-weight-xl text-center">Bedankt voor het inschrijven voor onze nieuwsbrief!</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BedanktContact